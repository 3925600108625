import {Injectable} from '@angular/core';
import {FrontendService} from './frontend.service';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';
import {CartService} from './cart.service';



@Injectable()

export class DateService {

   private date: any = false;
   private diff: number = 0;

    constructor(
        private http: HttpClient,
        private main_service: MainService,
        private cart_service: CartService
    ) {
    }


    load(setInt = false) : Promise <any> {

        if (setInt) {
            this.InitInterval();
        }
        return this.getServerDateTime();
    }

    getServerDateTime(){
        const self = this;
        return this.http.get(this.main_service.getFrontendUrl() + '/DateTime')
            .toPromise()
            .then((data: any) => {

                var response = data.DateTime + '';
                var vers = data.version;
                if (vers) {
                    var appVersion = vers + '';
                    if(localStorage.getItem('appVersion') && localStorage.getItem('appVersion') != '') {
                        if (localStorage.getItem('appVersion') != appVersion && typeof self.cart_service != 'undefined') {
                            localStorage.setItem('appVersion', appVersion);
                            self.cart_service.goToHome();
                        }
                    } else {
                        localStorage.setItem('appVersion', appVersion);
                    }
                }
                response = response.substring(0, 19);
                const servertime = new Date(response);
                const localtime = new Date();
                this.diff = servertime.getTime() - localtime.getTime();
            });
    }

    /**
     *
     *
     * @constructor
     */
    InitInterval(){
        setInterval( data => {
            this.getServerDateTime();
        }, 20000);
    }


     newDate() {

            const localtime = new Date();
            const neu = new Date(localtime.getTime() + this.diff);
            return neu;
    }

}
